import React from 'react';
import { useParams } from 'react-router-dom';
import ProductCarousel from './ProductCarousel';

const CategoryPage = ({ categorias, produtos }) => {
  const { id } = useParams();
  const categoriaId = parseInt(id);
  const categoria = categorias.find(cat => cat.id === categoriaId);
  const produtosDaCategoria = produtos.filter(produto => produto.categoriaId === categoriaId);

  return (
    <div>
      <h1>{categoria ? categoria.nome : 'Categoria não encontrada'}</h1>
      {produtosDaCategoria.length > 0 ? (
        produtosDaCategoria.map(produto => (
          <ProductCarousel key={produto.id} product={produto} />
        ))
      ) : (
        <p>Nenhum produto encontrado nesta categoria.</p>
      )}
    </div>
  );
};

export default CategoryPage;
