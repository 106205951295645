import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductCarousel = ({ product }) => {
  const { nome, descricao, preco, image } = product;
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-container">
      <Slider {...settings}>
        <div>
          <img src={image} alt={nome} />
        </div>
      </Slider>
      <div className="product-footer">
        <h3>{nome}</h3>
        <p>{descricao}</p>
        <span>Preço: R$ {preco.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default ProductCarousel;
