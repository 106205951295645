import React from 'react';
import { Link } from 'react-router-dom';
import './Category.css'; // Importando o arquivo de estilo

const Categories = ({ categorias }) => {
  return (
    <div className="categories-container">
      {categorias.map(categoria => (
        <Link key={categoria.id} to={`/categoria/${categoria.id}`} className="category-story">
          <img src={categoria.image} alt={categoria.nome} />
          <p>{categoria.nome}</p>
        </Link>
      ))}
    </div>
  );
};

export default Categories;
