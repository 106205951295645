import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Categories from './components/Categories';
import CategoryPage from './components/CategoryPage';
import Feed from './components/Feed';

function App() {
  const [categorias] = useState([
    { id: 1, nome: 'Entradas', image: 'https://imgur.com/l1ovPwZ.png' },
    { id: 2, nome: 'Combinados', image: 'https://imgur.com/jH5uh3K.png' },
    { id: 3, nome: 'Sushis', image: 'https://imgur.com/gVOLE9k.png' },
    { id: 4, nome: 'Sashimis', image: 'https://imgur.com/1lI6mYc.png' },
    { id: 5, nome: 'Bateras', image: 'https://imgur.com/WrxuH4T.png' },
    { id: 6, nome: 'Makimonos', image: 'https://imgur.com/zWvLNpO.png' },
    { id: 7, nome: 'Sushi a la Carte', image: 'https://imgur.com/DolW5LP.png' },
    { id: 8, nome: 'Sashimi a la Carte', image: 'https://imgur.com/bpQ3Z9I.png' },
    { id: 9, nome: 'Temakis', image: 'https://imgur.com/5ySZIop.png' },
    { id: 10, nome: 'Teishoku', image: 'https://imgur.com/VFdvNyp.png' },
    { id: 11, nome: 'Sobremesas', image: 'https://imgur.com/H0p2oo6.png' }
  ]);

  const [produtos] = useState([
    // Entradas (categoriaId: 1)
    { id: 1, nome: 'Fish Ball', descricao: 'Deliciosas bolinhas crocantes de peixe, perfeitas para começar sua refeição.', preco: 29.00, image: 'https://example.com/fish-ball.jpg', categoriaId: 1 },
    { id: 2, nome: 'Sunomono', descricao: 'Refrescante salada agridoce de pepino japonês, uma explosão de sabores.', preco: 17.00, image: 'https://example.com/sunomono.jpg', categoriaId: 1 },
    { id: 3, nome: 'Harumaki de queijo', descricao: 'Rolinho primavera recheado com queijo derretido, crocante por fora e cremoso por dentro.', preco: 25.00, image: 'https://example.com/harumaki-queijo.jpg', categoriaId: 1 },
    { id: 4, nome: 'Ceviche de salmão', descricao: 'Salmão fresco marinado em cítricos, combinando sabor e frescor.', preco: 59.90, image: 'https://example.com/ceviche-salmao.jpg', categoriaId: 1 },
    { id: 5, nome: 'Ceviche', descricao: 'Clássico ceviche com peixe branco fresco, temperado com limão e especiarias.', preco: 49.90, image: 'https://example.com/ceviche.jpg', categoriaId: 1 },
    { id: 6, nome: 'Edamame', descricao: 'Vagens de soja levemente salgadas, ideais para petiscar.', preco: 24.00, image: 'https://example.com/edamame.jpg', categoriaId: 1 },
    { id: 7, nome: 'Carpaccio de salmão', descricao: 'Finas fatias de salmão regadas com molho especial, uma opção leve e sofisticada.', preco: 99.00, image: 'https://example.com/carpaccio-salmao.jpg', categoriaId: 1 },
    { id: 8, nome: 'Tempurá de legumes', descricao: 'Legumes frescos empanados em massa leve e crocante.', preco: 49.00, image: 'https://example.com/tempura-legumes.jpg', categoriaId: 1 },
    { id: 9, nome: '½ Tempurá de legumes', descricao: 'Meia porção de tempurá de legumes, ideal para degustar.', preco: 29.50, image: 'https://example.com/tempura-legumes-half.jpg', categoriaId: 1 },
    { id: 10, nome: 'Tempurá de camarão', descricao: 'Camarões selecionados empanados, crocantes e suculentos.', preco: 149.50, image: 'https://example.com/tempura-camarao.jpg', categoriaId: 1 },
    { id: 11, nome: '½ Tempurá de camarão', descricao: 'Meia porção de tempurá de camarão para compartilhar.', preco: 79.50, image: 'https://example.com/tempura-camarao-half.jpg', categoriaId: 1 },
    { id: 12, nome: 'Tempurá misto', descricao: 'Mix de frutos do mar e legumes empanados, uma combinação perfeita.', preco: 129.00, image: 'https://example.com/tempura-misto.jpg', categoriaId: 1 },
    { id: 13, nome: '½ Tempurá misto', descricao: 'Meia porção do nosso tempurá misto.', preco: 69.50, image: 'https://example.com/tempura-misto-half.jpg', categoriaId: 1 },
    { id: 14, nome: 'Camarão crocante', descricao: 'Camarões empanados com crocância inigualável.', preco: 59.00, image: 'https://example.com/camarao-crocante.jpg', categoriaId: 1 },
    { id: 15, nome: 'Cogumelos na manteiga', descricao: 'Cogumelos frescos salteados na manteiga, um sabor marcante.', preco: 59.00, image: 'https://example.com/cogumelos-manteiga.jpg', categoriaId: 1 },
    { id: 16, nome: 'Guioza', descricao: 'Pasteizinhos japoneses recheados com carne e vegetais, grelhados na medida certa.', preco: 29.00, image: 'https://example.com/guioza.jpg', categoriaId: 1 },
    { id: 17, nome: 'Chicken Karaague', descricao: 'Pedaços de frango marinados e fritos, crocantes por fora e suculentos por dentro.', preco: 24.00, image: 'https://example.com/chicken-karaague.jpg', categoriaId: 1 },

    // Combinados (categoriaId: 2)
    { id: 18, nome: 'Combinado Mini - 19 unidades', descricao: 'Seleção de sushis e sashimis perfeitos para uma pessoa.', preco: 125.00, image: 'https://example.com/combinado-mini.jpg', categoriaId: 2 },
    { id: 19, nome: 'Combinado Itinin-mai - 23 unidades', descricao: 'Variedade de peças tradicionais para apreciar o melhor da culinária japonesa.', preco: 155.00, image: 'https://example.com/combinado-itinin-mai.jpg', categoriaId: 2 },
    { id: 20, nome: 'Combinado Ninin-mai - 46 unidades', descricao: 'Combinado generoso ideal para compartilhar a dois.', preco: 299.00, image: 'https://example.com/combinado-ninin-mai.jpg', categoriaId: 2 },
    { id: 21, nome: 'Combinado Sannin-mai - 69 unidades', descricao: 'Perfeito para grupos, com uma variedade completa de sabores.', preco: 459.00, image: 'https://example.com/combinado-sannin-mai.jpg', categoriaId: 2 },

    // Sushis (categoriaId: 3)
    { id: 22, nome: 'Sushi Itinin-mai - 14 unidades', descricao: 'Conjunto de sushis clássicos para uma experiência autêntica.', preco: 89.00, image: 'https://example.com/sushi-itinin-mai.jpg', categoriaId: 3 },
    { id: 23, nome: 'Sushi Ninin-mai - 28 unidades', descricao: 'Dobro de sabor para compartilhar momentos especiais.', preco: 178.00, image: 'https://example.com/sushi-ninin-mai.jpg', categoriaId: 3 },
    { id: 24, nome: 'Sushi Sannin-mai - 42 unidades', descricao: 'Uma festa de sushis para todos os gostos.', preco: 269.00, image: 'https://example.com/sushi-sannin-mai.jpg', categoriaId: 3 },
    { id: 25, nome: 'Sushi Especial Itinin-mai - 16 unidades', descricao: 'Seleção especial de sushis premium.', preco: 155.00, image: 'https://example.com/sushi-especial-itinin-mai.jpg', categoriaId: 3 },
    { id: 26, nome: 'Sushi Especial Ninin-mai - 32 unidades', descricao: 'Sushis especiais para uma experiência gourmet.', preco: 299.00, image: 'https://example.com/sushi-especial-ninin-mai.jpg', categoriaId: 3 },
    { id: 27, nome: 'Sushi Especial Sannin-mai - 48 unidades', descricao: 'O melhor dos sushis especiais para compartilhar.', preco: 459.00, image: 'https://example.com/sushi-especial-sannin-mai.jpg', categoriaId: 3 },

    // Sashimis (categoriaId: 4)
    { id: 28, nome: 'Sashimi Itinin-mai - 16 fatias', descricao: 'Fatias frescas de peixe selecionado.', preco: 109.00, image: 'https://example.com/sashimi-itinin-mai.jpg', categoriaId: 4 },
    { id: 29, nome: 'Sashimi Ninin-mai - 32 fatias', descricao: 'Dobro de fatias para apreciar com companhia.', preco: 209.00, image: 'https://example.com/sashimi-ninin-mai.jpg', categoriaId: 4 },
    { id: 30, nome: 'Sashimi Sannin-mai - 48 fatias', descricao: 'Generosa porção de sashimis para compartilhar.', preco: 299.00, image: 'https://example.com/sashimi-sannin-mai.jpg', categoriaId: 4 },

    // Baterás (categoriaId: 5)
    { id: 31, nome: 'Baterá de ceviche - 4 unidades', descricao: 'Saborosa combinação de arroz e ceviche prensados.', preco: 39.00, image: 'https://example.com/batera-ceviche-4.jpg', categoriaId: 5 },
    { id: 32, nome: 'Baterá de ceviche - 8 unidades', descricao: 'Dobro de sabor com nosso baterá de ceviche.', preco: 60.00, image: 'https://example.com/batera-ceviche-8.jpg', categoriaId: 5 },
    { id: 33, nome: 'Baterá de salmão - 4 unidades', descricao: 'Baterá tradicional com salmão fresco.', preco: 57.00, image: 'https://example.com/batera-salmao-4.jpg', categoriaId: 5 },
    { id: 34, nome: 'Baterá de salmão - 8 unidades', descricao: 'Mais sabor com 8 unidades do nosso baterá de salmão.', preco: 85.00, image: 'https://example.com/batera-salmao-8.jpg', categoriaId: 5 },
    { id: 35, nome: 'Baterá de salmão skin - 4 unidades', descricao: 'Baterá com pele de salmão crocante.', preco: 42.00, image: 'https://example.com/batera-salmao-skin-4.jpg', categoriaId: 5 },
    { id: 36, nome: 'Baterá de salmão skin - 8 unidades', descricao: 'Aprecie mais do sabor único do salmão skin.', preco: 70.00, image: 'https://example.com/batera-salmao-skin-8.jpg', categoriaId: 5 },
    { id: 37, nome: 'Baterá Spicy tuna - 4 unidades', descricao: 'Atum picante em um delicioso baterá.', preco: 35.00, image: 'https://example.com/batera-spicy-tuna-4.jpg', categoriaId: 5 },
    { id: 38, nome: 'Baterá Spicy tuna - 8 unidades', descricao: 'Para os amantes de picância, 8 unidades.', preco: 55.00, image: 'https://example.com/batera-spicy-tuna-8.jpg', categoriaId: 5 },
    { id: 39, nome: 'Baterá Misto - 6 unidades', descricao: 'Mix de nossos melhores baterás.', preco: 70.00, image: 'https://example.com/batera-misto-6.jpg', categoriaId: 5 },
    { id: 40, nome: 'Baterá Misto - 12 unidades', descricao: 'Degustação completa dos nossos baterás.', preco: 99.00, image: 'https://example.com/batera-misto-12.jpg', categoriaId: 5 },

    // Makimonos (categoriaId: 6)
    { id: 41, nome: 'Hossomaki de Pepino - 6 unidades', descricao: 'Rolinho de arroz com pepino fresco.', preco: 19.00, image: 'https://example.com/hossomaki-pepino.jpg', categoriaId: 6 },
    { id: 42, nome: 'Hossomaki de Atum - 6 unidades', descricao: 'Rolinho fino com atum fresco.', preco: 38.00, image: 'https://example.com/hossomaki-atum.jpg', categoriaId: 6 },
    { id: 43, nome: 'Hossomaki de Salmão - 6 unidades', descricao: 'Rolinho fino com salmão de qualidade.', preco: 38.00, image: 'https://example.com/hossomaki-salmao.jpg', categoriaId: 6 },
    { id: 44, nome: 'Uramaki de Atum - 8 unidades', descricao: 'Arroz por fora e atum por dentro, uma delícia invertida.', preco: 49.00, image: 'https://example.com/uramaki-atum.jpg', categoriaId: 6 },
    { id: 45, nome: 'Uramaki Spicy tuna - 8 unidades', descricao: 'Uramaki com atum picante, para quem gosta de um toque a mais.', preco: 49.00, image: 'https://example.com/uramaki-spicy-tuna.jpg', categoriaId: 6 },
    { id: 46, nome: 'Uramaki de Salmão - 8 unidades', descricao: 'Uramaki clássico com salmão fresco.', preco: 49.00, image: 'https://example.com/uramaki-salmao.jpg', categoriaId: 6 },
    { id: 47, nome: 'Uramaki Tempurá Camarão - 8 unidades', descricao: 'Uramaki recheado com tempurá de camarão crocante.', preco: 59.00, image: 'https://example.com/uramaki-tempura-camarao.jpg', categoriaId: 6 },

    // Sushi a la Carte (categoriaId: 7)
    { id: 48, nome: 'Dupla - Sushi de Salmão', descricao: 'Duas unidades de sushi de salmão fresco.', preco: 26.00, image: 'https://example.com/sushi-salmao.jpg', categoriaId: 7 },
    { id: 49, nome: 'Dupla - Sushi de Salmão Trufado', descricao: 'Sushi de salmão com um toque especial de trufas.', preco: 34.00, image: 'https://example.com/sushi-salmao-trufado.jpg', categoriaId: 7 },
    { id: 50, nome: 'Dupla - Sushi de Atum', descricao: 'Duas unidades de sushi de atum selecionado.', preco: 26.00, image: 'https://example.com/sushi-atum.jpg', categoriaId: 7 },
    { id: 51, nome: 'Dupla - Sushi Peixe do dia', descricao: 'Sushi preparado com o peixe fresco do dia.', preco: 26.00, image: 'https://example.com/sushi-peixe-do-dia.jpg', categoriaId: 7 },
    { id: 52, nome: 'Dupla - Sushi Camarão', descricao: 'Sushi de camarão cozido, sabor suave.', preco: 42.00, image: 'https://example.com/sushi-camarao.jpg', categoriaId: 7 },
    { id: 53, nome: 'Dupla - Sushi de Vieira', descricao: 'Sushi delicado com vieiras frescas.', preco: 50.00, image: 'https://example.com/sushi-vieira.jpg', categoriaId: 7 },

    // Sashimi a la Carte (categoriaId: 8)
    { id: 54, nome: 'Sashimi de Atum - 3 fatias', descricao: 'Fatias de atum fresco, pura essência do mar.', preco: 27.00, image: 'https://example.com/sashimi-atum.jpg', categoriaId: 8 },
    { id: 55, nome: 'Sashimi de Salmão - 3 fatias', descricao: 'Salmão cortado na perfeição, textura e sabor incomparáveis.', preco: 29.00, image: 'https://example.com/sashimi-salmao.jpg', categoriaId: 8 },
    { id: 56, nome: 'Sashimi de Peixe do dia - 3 fatias', descricao: 'Surpreenda-se com o peixe mais fresco disponível.', preco: 35.00, image: 'https://example.com/sashimi-peixe-dia.jpg', categoriaId: 8 },
    { id: 57, nome: 'Sashimi de Vieira - 3 fatias', descricao: 'Vieiras frescas e macias, uma iguaria.', preco: 24.00, image: 'https://example.com/sashimi-vieira.jpg', categoriaId: 8 },

    // Temakis (categoriaId: 9)
    { id: 58, nome: 'Temaki de Atum', descricao: 'Cone de alga recheado com atum fresco.', preco: 26.00, image: 'https://example.com/temaki-atum.jpg', categoriaId: 9 },
    { id: 59, nome: 'Temaki Spicy tuna', descricao: 'Temaki de atum com toque picante.', preco: 28.00, image: 'https://example.com/temaki-spicy-tuna.jpg', categoriaId: 9 },
    { id: 60, nome: 'Temaki Salmão', descricao: 'Recheado com salmão fresco e saboroso.', preco: 28.00, image: 'https://example.com/temaki-salmao.jpg', categoriaId: 9 },
    { id: 61, nome: 'Temaki Tempurá de Camarão', descricao: 'Camarão crocante envolto em arroz e alga.', preco: 28.00, image: 'https://example.com/temaki-tempura-camarao.jpg', categoriaId: 9 },

    // Teishoku (Menu Executivo) (categoriaId: 10)
    { id: 62, nome: 'Teishoku Tempurá', descricao: 'Prato completo com tempurá, arroz, missoshiru e acompanhamentos.', preco: 100.00, image: 'https://example.com/teishoku-tempura.jpg', categoriaId: 10 },
    { id: 63, nome: 'Teishoku Yakisoba Legumes', descricao: 'Yakisoba de legumes com acompanhamentos tradicionais.', preco: 65.00, image: 'https://example.com/teishoku-yakisoba-legumes.jpg', categoriaId: 10 },
    { id: 64, nome: 'Teishoku Yakisoba Bovino', descricao: 'Yakisoba com carne bovina, uma combinação deliciosa.', preco: 75.00, image: 'https://example.com/teishoku-yakisoba-bovino.jpg', categoriaId: 10 },
    { id: 65, nome: 'Teishoku Yakisoba Frango', descricao: 'Yakisoba de frango com vegetais frescos.', preco: 75.00, image: 'https://example.com/teishoku-yakisoba-frango.jpg', categoriaId: 10 },
    { id: 66, nome: 'Teishoku Yakisoba Camarão', descricao: 'Yakisoba de camarão com sabores do mar.', preco: 85.00, image: 'https://example.com/teishoku-yakisoba-camarao.jpg', categoriaId: 10 },
    { id: 67, nome: 'Teishoku Shogayaki Bovino', descricao: 'Fatias de carne bovina ao molho de gengibre.', preco: 75.00, image: 'https://example.com/teishoku-shogayaki-bovino.jpg', categoriaId: 10 },
    { id: 68, nome: 'Teishoku Shogayaki Suíno', descricao: 'Carne suína ao molho de gengibre, suculenta e saborosa.', preco: 75.00, image: 'https://example.com/teishoku-shogayaki-suino.jpg', categoriaId: 10 },
    { id: 69, nome: 'Teishoku Sashimi', descricao: 'Prato completo com sashimis variados e acompanhamentos.', preco: 85.00, image: 'https://example.com/teishoku-sashimi.jpg', categoriaId: 10 },
    { id: 70, nome: 'Teishoku Salmão', descricao: 'Salmão grelhado servido com acompanhamentos tradicionais.', preco: 78.00, image: 'https://example.com/teishoku-salmao.jpg', categoriaId: 10 },
    { id: 71, nome: 'Teishoku Gyukatsu', descricao: 'Carne bovina empanada ao estilo japonês.', preco: 85.00, image: 'https://example.com/teishoku-gyukatsu.jpg', categoriaId: 10 },
    { id: 72, nome: 'Teishoku Tonkatsu', descricao: 'Lombo suíno empanado e crocante.', preco: 80.00, image: 'https://example.com/teishoku-tonkatsu.jpg', categoriaId: 10 },
    { id: 73, nome: 'Teishoku Chickenkatsu', descricao: 'Frango empanado, crocante e suculento.', preco: 85.00, image: 'https://example.com/teishoku-chickenkatsu.jpg', categoriaId: 10 },

    // Sobremesas (categoriaId: 11)
    { id: 74, nome: 'Abacaxi com Raspas de Limão Siciliano', descricao: 'Sobremesa leve e refrescante para finalizar sua refeição.', preco: 15.00, image: 'https://example.com/abacaxi-limao.jpg', categoriaId: 11 },
    { id: 75, nome: 'Flan de Baunilha com Calda de Caramelo', descricao: 'Flan suave de baunilha com calda de caramelo artesanal.', preco: 17.00, image: 'https://example.com/flan-baunilha.jpg', categoriaId: 11 },
    { id: 76, nome: 'Sorbet de Maçã Verde com Gelatina de Sake', descricao: 'Sobremesa gelada e sofisticada com toque japonês.', preco: 19.00, image: 'https://example.com/sorbet-maca-verde.jpg', categoriaId: 11 },
    { id: 77, nome: 'Tempurá de Banana com Sorvete de Mel', descricao: 'Banana empanada servida com sorvete de mel.', preco: 25.00, image: 'https://example.com/tempura-banana.jpg', categoriaId: 11 },
    { id: 78, nome: 'Petit Gateau de Chocolate com Sorvete de Mel e Calda de Gengibre', descricao: 'Delicioso bolo de chocolate com recheio cremoso, acompanhado de sorvete.', preco: 27.00, image: 'https://example.com/petit-gateau.jpg', categoriaId: 11 }
  ]);

  const [posts] = useState([
    { id: 1, type: 'image', src: 'https://imgur.com/sOX2xPj.png', description: 'Foto especial do Chef' },
    { id: 2, type: 'video', src: 'https://example.com/chef_video.mp4', description: 'Mensagem do Chef Jun' }
  ]);

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<>
            <Categories categorias={categorias} />
            <Feed posts={posts} />
          </>} />
          <Route path="/categoria/:id" element={<CategoryPage categorias={categorias} produtos={produtos} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
