import React from 'react';
import './Feed.css';

const Feed = ({ posts }) => {
  return (
    <div className="feed-container">
      {posts.map(post => (
        <div key={post.id} className="post-container">
          <img src={post.src} alt={post.description} className="post-image" />
          <div className="post-description">{post.description}</div>
        </div>
      ))}
    </div>
  );
};

export default Feed;
